import parser from 'ua-parser-js'

export const isRouteGoal = (campaigns, route) =>
  campaigns.filter((c) => c.conversion_page.linkedItems.find((p) => p.elements.url.value === route))

export const isTrackLeadGoal = (campaigns) => {
  return campaigns.value.filter((c) => c.conversion_tracklead.value[0]?.codename === 'yes')
}

export const findCampaignIndex = (kenticoCampaigns, vwoCampaigns) => {
  const campaign = kenticoCampaigns.find(
    (c) =>
      c.elements.home_page.value[0]?.codename === 'yes' &&
      vwoCampaigns.find((k) => k.key === c.elements.campaign_key.value)
  )
  return campaign ? campaign.elements : null
}

export const findCampaignPage = (kenticoCampaigns, vwoCampaigns, route) => {
  let url = route
  if (!url.endsWith('/')) url = `${route}/`

  const campaign = kenticoCampaigns.find((c) => {
    return (
      c.elements.pages.linkedItems.find((p) => p.elements.url.value === url) &&
      vwoCampaigns.find((k) => k.key === c.elements.campaign_key.value)
    )
  })
  return campaign ? campaign.elements : null
}

export const findAllPages = (kenticoCampaigns, vwoCampaigns) => {
  const allPageCampaigns = kenticoCampaigns.filter(
    (c) =>
      c.elements.all_pages.value[0]?.codename === 'yes' &&
      vwoCampaigns.find((k) => k.key === c.element.campaign_key.value)
  )
  return allPageCampaigns.length ? allPageCampaigns.map((c) => c.elements) : []
}

export const replaceUrlPath = (kenticoCampaigns, vwoCampaigns, route) => {
  let url = route
  if (!url.endsWith('/')) url = `${route}/`

  const vwoUrl = kenticoCampaigns.value.find((c) => {
    return (
      c?.assignedVariantName.includes('Variation') &&
      !!c.url_swap.linkedItems.length &&
      !!vwoCampaigns.value.find((k) => k.key === c.campaign_key.value) &&
      ((c.home_page?.value[0]?.codename === 'yes' && url === '/') ||
        c.pages.linkedItems.find((p) => p.elements.url.value === url) ||
        c.all_pages.value[0]?.codename === 'yes')
    )
  })
  return vwoUrl ? vwoUrl.url_swap.linkedItems[0].elements : null
}

export const colourSwap = (kenticoCampaigns, vwoCampaigns, route) => {
  let url = route
  if (!url.endsWith('/')) url = `${route}/`

  const colour = kenticoCampaigns.value.find((c) => {
    return (
      c?.assignedVariantName.includes('Variation') &&
      !!c.colour_swap.value &&
      !!vwoCampaigns.value.find((k) => k.key === c.campaign_key.value) &&
      ((c.home_page?.value[0]?.codename === 'yes' && url === '/') ||
        c.pages.linkedItems.find((p) => p.elements.url.value === url) ||
        c.all_pages.value[0]?.codename === 'yes')
    )
  })
  return colour ? colour.colour_swap.value.split(',') : ''
}

export const customCss = (kenticoCampaigns, vwoCampaigns, route) => {
  let url = route
  if (!url.endsWith('/')) url = `${route}/`

  const css = kenticoCampaigns.value.find((c) => {
    return (
      c?.assignedVariantName.includes('Variation') &&
      !!c.custom_css.value &&
      !!vwoCampaigns.value.find((k) => k.key === c.campaign_key.value) &&
      ((c.home_page?.value[0]?.codename === 'yes' && url === '/') ||
        c.pages.linkedItems.find((p) => p.elements.url.value === url) ||
        c.all_pages.value[0]?.codename === 'yes')
    )
  })
  return css ? css.custom_css.value : ''
}

export const visitorDimensionMap = (req, route) => {
  const ua = parser(req.headers['user-agent'])

  return {
    // mapped to custom dimensions on VWO settings custom dimensions
    OnlineChannelGroup: '',
    WebRefCode: '',
    UserAgent:
      req.headers['user-agent'].length > 255
        ? req.headers['user-agent'].substring(0, 255)
        : req.headers['user-agent'],
    Browser: ua.browser.name || 'Unknown',
    BrowserVersion: ua.browser.version || 'Unknown',
    OperatingSystem: ua.os.name || 'Unknown',
    OperatingSystemVersion: ua.os.version || 'Unknown',
    DeviceCategory: ua.device.type || 'Desktop',
    LandingPage: route,
    LandingPageFull: req.url,
    VisitorID: '',
    TransactionID: '',
    IPAddress: ''
  }
}
