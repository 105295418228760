import { callAPI } from '#root/utils/helpers/helperApi.js'

export const getCallcenterHours = ({
  daysToSchedule,
  encryptedEntityId,
  entityType,
  phoneNumber,
  provinceId
}) =>
  callAPI({
    url: '/wrapup/callcenter/available-hours',
    method: 'get',
    params: {
      daysToSchedule,
      encryptedEntityId,
      entityType,
      phoneNumber,
      provinceId
    }
  })

export const getCallbackHours = ({
  interval,
  daysToSchedule,
  startDate = null,
  callCenter = 'GBCallCenter'
}) =>
  callAPI({
    url: '/wrapup/callbacks/hours',
    method: 'get',
    params: { interval, daysToSchedule, startDate, callCenter }
  })
