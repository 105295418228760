import { defineStore } from 'pinia'
import { useCustomerStore } from '#root/stores/storeCustomer.js'
import { useTrackingStore } from '#root/stores/storeTracking.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useVWOStore } from '#root/stores/storeVWO.js'
import { useSetter } from '#root/utils/helpers/helperRef.js'
import { removeNullProperties } from '#root/utils/helpers/helperData.js'
import { formTypes } from '#root/utils/types/form.js'
import { trackingTypes } from '#root/utils/types/tracking.js'
import { Customer } from '#root/utils/models/Customer.js'
import { quoteRequest, postCallMeNow, postInfoPack, postWillKit } from '#root/api/leads.js'
import { postLeadByte } from '#root/api/leadbyte.js'
import { postAdsonarTrack } from '#root/api/adsonar.js'
//TODO NTS: APIS
//import { apiLeads, apiTelephony, apiCustomers } from '@/api'

export const useFormStore = defineStore('form', () => {
  const [formSubmitted, setFormSubmitted] = useSetter(null)
  const [activeStep, setActiveStep] = useSetter({
    step: '',
    index: 0
  })
  const { setCustomer, setProductCode } = useCustomerStore()
  const { trackUserInfo, trackLead } = useTrackingStore()
  const getCustomerFromFields = (values) => {
    const customer = new Customer()
    customer.ageCheck = values.ageCheck || values.ageCheckRange === 'Yes'
    customer.firstName = values.firstName
    customer.lastName = values.lastName
    customer.phone = values.phone
    customer.email = values.email
    customer.postCode = values.postCode
    customer.provinceId = values.provinceId
    customer.dob = values.dob ? values.dob.toNumeric() : null
    customer.coverGroup = values.coverGroup
    customer.coverAmount = values.coverAmount
    customer.coverType = values.coverType
    customer.gender = values.gender
    customer.smoker = values.smoker
    return customer
  }

  const createLead = async ({ values, context, env }, pageContext) => {
    const globalStore = useGlobalStore()
    const customer = getCustomerFromFields(values)
    context.transactionId = globalStore.transactionId
    setCustomer(customer)
    setProductCode(context.productCode)

    switch (context.formType) {
      case formTypes.leadbyte:
        return leadByteForm({ values, context, env }, pageContext)
      case formTypes.quote:
        return requestQuoteForm({ values, context, env }, pageContext)
      case formTypes.banner:
        return requestQuoteForm({ values, context, env }, pageContext)
      case formTypes.funnel:
        return requestQuoteForm({ values, context, env }, pageContext)
      case formTypes.callMeNow:
        return callbackForm({ values, context })
      case formTypes.infoPack:
        return infoPackForm({ values, context })
      case formTypes.willKit:
        return willKitForm({ values, context })
    }
  }

  const leadByteForm = ({ context, values, env }, pageContext) => {
    const { setOngoingLeadType } = useTrackingStore()
    const { vwoTrackLead } = useVWOStore()
    const { setLeadbyteQueueId } = useCustomerStore()

    const customer = getCustomerFromFields(values)
    const { queryParams, transactionId } = useGlobalStore()
    const lead = {
      c1: queryParams['c1'] ? queryParams['c1'] : queryParams.utm_term,
      c2: queryParams['c2'] ? queryParams['c2'] : queryParams.utm_content,
      c3: queryParams['c3'],
      coveramount: customer.coverAmount,
      covertype: customer.coverType,
      dob: customer.dob?.toPostString(),
      email: customer.email,
      firstName: customer.firstName,
      gender: customer.gender,
      lastName: customer.lastName,
      phone1: customer.phone,
      // we are sending the province name EXACTLY as it is displayed on the screen, this requires coordination on the leadbyte side
      province: values.provinceName ?? '',
      singlejoint: customer.coverGroup,
      smoker: customer.smokerStatus,
      transactionid: transactionId
    }

    trackUserInfo(customer.mapToTracking())
    return postLeadByte(lead).then((response) => {
      // if no queue, that means there was a validation error on the leadbyte side and we will do nothing (per Tyler)
      if (!response.errors.length && response.queueId) {
        setLeadbyteQueueId(response.queueId)
      }
      setOngoingLeadType(trackingTypes.LeadByte)
      trackLead({
        leadType: trackingTypes.LeadByte,
        ...(context['trackLeadFormType'] ? { formType: context['trackLeadFormType'] } : {}),
        ...(context['gtmCompanyName'] ? { gtmCompanyName: context['gtmCompanyName'] } : {})
      })
      vwoTrackLead(pageContext)
      setFormSubmitted(true)
    })
  }

  const requestQuoteForm = ({ context, values, env }, pageContext) => {
    const { setOngoingLeadType } = useTrackingStore()
    const { vwoTrackLead } = useVWOStore()
    const globalStore = useGlobalStore()
    const customer = getCustomerFromFields(values)
    const data = removeNullProperties(customer.mapToServices())
    data.transactionId = context.transactionId
    data.title = null
    data.productCode = context.productCode
    if (context.productCode === 'GENERIC') data.productType = 5
    data.callMeLaterOffered = context.callMeLaterOffered
    trackUserInfo(customer.mapToTracking())
    if (env.ADSONAR) {
      postAdsonarTrack({
        refcode: globalStore.config.refCode,
        campaign: globalStore.queryParams['utm_campaignid']
          ? globalStore.queryParams['utm_campaignid']
          : globalStore.queryParams['utm_campaign'],
        source: globalStore.queryParams['utm_source'],
        medium: globalStore.queryParams['utm_medium']
      })
    }

    return quoteRequest({ data }).then(() => {
      setOngoingLeadType(trackingTypes.QuoteRequest)
      trackLead({
        leadType: trackingTypes.QuoteRequest,
        ...(context['trackLeadFormType'] ? { formType: context['trackLeadFormType'] } : {}),
        ...(context['gtmCompanyName'] ? { gtmCompanyName: context['gtmCompanyName'] } : {})
      })
      vwoTrackLead(pageContext)
      setFormSubmitted(true)
    })
  }

  const callbackForm = ({ context, values }) => {
    const customer = getCustomerFromFields(values)
    const data = removeNullProperties(customer.mapToServices())
    data.transactionId = context.transactionId
    data.productCode = context.productCode
    if (context.productCode === 'GENERIC') data.productType = 5
    trackUserInfo(customer.mapToTracking())

    return postCallMeNow({ data }).then(() => {
      trackLead({ leadType: trackingTypes.CallMeNow })
      setFormSubmitted(true)
      //this.showThanksModal = true
    })
  }

  const infoPackForm = ({ values, context }) => {
    const { setOngoingLeadType } = useTrackingStore()
    const customer = getCustomerFromFields(values)
    const data = removeNullProperties(customer.mapToServices())
    data.transactionId = context.transactionId
    data.productCode = context.productCode
    if (context.productCode === 'GENERIC') data.productType = 5
    trackUserInfo(customer.mapToTracking())

    return postInfoPack({ data }).then(() => {
      setOngoingLeadType(trackingTypes.InfoPackRequest)
      trackLead({ leadType: trackingTypes.InfoPackRequest })
      setFormSubmitted(true)
      // this.showThanksCopy = true
    })
  }

  const willKitForm = ({ values, context }) => {
    const { setOngoingLeadType } = useTrackingStore()
    const customer = getCustomerFromFields(values)
    const data = removeNullProperties(customer.mapToServices())
    data.transactionId = context.transactionId
    data.productCode = context.productCode
    if (context.productCode === 'GENERIC') data.productType = 5
    trackUserInfo(customer.mapToTracking())

    return postWillKit({ data }).then(() => {
      setOngoingLeadType(trackingTypes.WillKit)
      trackLead({ leadType: trackingTypes.WillKit })
      setFormSubmitted(true)
      // this.showThanksCopy = true
    })
  }

  return {
    createLead,
    requestQuoteForm,
    callbackForm,
    infoPackForm,
    willKitForm,
    formSubmitted,
    activeStep,
    setActiveStep
  }
})
