export const asyncCompObserver = (elm, type) => {
  if (!elm) return null
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          type.value = true
          observer.disconnect()
        }
      })
    },
    { threshold: 0.1 }
  )
  observer.observe(elm)
}
