import { defineStore } from 'pinia'
import { useSetter } from '#root/utils/helpers/helperRef.js'

export const useUnsubscribeStore = defineStore('unsubscribe', () => {
  const [unsubscribe, setUnsubscribe] = useSetter(null)

  return {
    unsubscribe,
    setUnsubscribe
  }
})
