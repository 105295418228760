import { defineStore } from 'pinia'
import { useContentStore } from '#root/stores/storeContent.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useVWOStore } from '#root/stores/storeVWO.js'
import { modes } from '#root/utils/types/app.js'

export const useInitStore = defineStore('init', () => {
  // server-side only
  // All of the following init actions are only executed server-side

  const serverInit = async ({ pageContext, store }) => {
    await Promise.all([serverInitContent(store), serverInitConfig(pageContext, store)])

    if (pageContext.env.MODE === modes.DEFAULT) {
      try {
        await trackingInit(pageContext, store)
      } catch {
        // getTransaction or initVWO failed, continue to render page
      }
    }
  }
  const trackingInit = async (pageContext, store) => {
    const vwoStore = useVWOStore(store)
    const globalStore = useGlobalStore(store)
    // const buyOnlineStore = useBuyOnlineStore()
    if (globalStore.isBot) {
      return
    }

    await Promise.all([globalStore.getTransaction(pageContext), globalStore.updateVisitorLocation()])

    // buyOnlineStore.getProducts()
    // needs to fire once getTransaction has completed
    await vwoStore.initVwoFullStackSettings({
      route: pageContext.urlPathname,
      query: pageContext.urlParsed.search,
      req: pageContext.request
    })
    globalStore.setTrackingEnabled(true)
  }

  const serverInitConfig = async ({ request, env }, store) => {
    const { helpers } = await import('#server')
    const globalStore = useGlobalStore(store)
    const ipAddress = helpers.routes.getClientIP(request)
    const isBot = helpers.routes.isBotRequest(request)
    const brandId = env.BRAND_ID

    globalStore.setConfig({ brandId: parseInt(brandId) })
    globalStore.setVisitorIP(ipAddress)
    globalStore.setLocale(env.BRANDLOCALE)
    globalStore.setIsBot(isBot)
    if (env.MODE === modes.DEFAULT) await globalStore.fetchCallbackHours(request)
  }

  const serverInitContent = async (store) => {
    const contentStore = useContentStore(store)
    await contentStore.init()
    await Promise.all([
      contentStore.getOptions(),
      contentStore.getHeader(),
      contentStore.getFooter(),
      ...[process.env.BRAND === 'legobox' ? contentStore.getSidebar() : []],
      contentStore.getCampaigns() // get vwo campaigns
    ])
  }

  return {
    serverInit,
    serverInitConfig,
    serverInitContent,
    trackingInit
  }
})
