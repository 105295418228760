// Like array.reduce but with less overhead for creating objects
// Takes in array and function that maps each array item to a [key, value] pair array
// for the new object.
// You can also return a [value] array of length=1 and the key will default to the array item
export const reduceToObject = (array, fn = (value, index) => [value, index]) => {
  return array.reduce((object, value, index) => {
    const [newValue, field = value] = [...fn(value, index)].reverse()

    return {
      ...object,
      [field]: newValue
    }
  }, {})
}

// Filter out object fields by test function
//  (value?: any, field?: string) => boolean
// (return value represents whether field gets to stay)
// by default removes falsy values if no function provided
export const filter = (obj, fn = (value) => !!value) =>
  Object.entries(obj).reduce(
    (obj, [field, value]) => (fn(value, field) ? { ...obj, [field]: value } : obj),
    {}
  )

export const removeNullProperties = (origObj) => filter(origObj, (value) => value !== null)

export const capitalizeFirstLetter = (data) => {
  if (!data) return
  const text = data.toLowerCase()
  return text.charAt(0).toUpperCase() + text.slice(1)
}
