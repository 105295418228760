import { useGetContentPage, useGetContent } from '#root/components/composables/getContent.js'

export const useSetBGColourPageOption = (elm, pointer) => {
  elm.value = getComputedStyle(document.documentElement).getPropertyValue(
    `--${useGetContentPage(pointer, '')}`
  )
}

// value wont be available until the component is mounted, so not great for SSR/setting initial value
export const useSetBGColourCompOption = (elm, pointer, content) => {
  elm.value = getComputedStyle(document.documentElement).getPropertyValue(
    `--${useGetContent(content, pointer, '')}`
  )
}
