<template>
  <div :class="['landing-page-template', ...vwoKeys, brandClass, pageButtonColour ? 'page-btn-colour' : '']">
    <!-- Header -->
    <GlobalHeader
      v-if="pageHeader || globalHeader"
      :content="pageHeader || globalHeader"
      :nofollow="noFollow"
      :is-homepage="isHomepage"
      :content-id="contentHeaderId"
      :cookie="cookies"
    />

    <TrustPilot v-if="trustPilot" :content="trustPilot" codename="trustPilot.system.codename" />

    <PageBlocks v-if="pagesStore.template === 'Page'" />
    <FunnelBlocks v-if="pagesStore.template === 'Funnel'" />

    <!-- Footer -->
    <GlobalFooter
      v-if="pageFooter || globalFooter"
      :content="pageFooter || globalFooter"
      :nofollow="noFollow"
      :content-id="contentFooterId"
    />
  </div>

  <template v-if="afterMount">
    <Modals />
  </template>
</template>

<script setup>
import { onMounted, onUpdated, computed, defineAsyncComponent, onBeforeMount, ref } from 'vue'
import { usePageContext } from '#root/renderer/usePageContext'
import { useSetBGColourPageOption } from '#root/components/composables/setBGColor.js'
import { useVWOStore } from '#root/stores/storeVWO.js'
import { usePagesStore } from '#root/stores/storePages.js'
import {
  useGetContentPage,
  useGetContentFooter,
  useGetContentHeader,
  useGetContentOptions
} from '#root/components/composables/getContent.js'

import GlobalHeader from '#root/components/global/GlobalHeader.vue'
import GlobalFooter from '#root/components/global/GlobalFooter.vue'

const FunnelBlocks = defineAsyncComponent(() => import('#root/components/templates/FunnelBlocks.vue'))
const PageBlocks = defineAsyncComponent(() => import('#root/components/templates/PageBlocks.vue'))
const Modals = defineAsyncComponent(() => import('#root/components/modals/Modals.vue'))
const TrustPilot = defineAsyncComponent(() => import('#root/components/pageblocks/TrustPilot.vue'))

const pageContext = usePageContext()
const vwoStore = useVWOStore()
const pagesStore = usePagesStore()
const afterMount = ref(false)
const brandClass = computed(() => {
  return pageContext.env.BRAND ? `${pageContext.env.BRAND}-layout` : ''
})
const pageButtonColour = computed(() => {
  return !!useGetContentPage('button_colour.value[0].name', false)
})
let pageButtonColourVar = ref('')

const trustPilot = computed(() => {
  return (
    useGetContentPage('trust_pilot.linkedItems[0]', null) ||
    useGetContentPage('trust_pilot_module.linkedItems[0]', null)
  )
})
const globalHeader = computed(() => {
  return useGetContentHeader('elements', null)
})
const pageHeader = computed(() => {
  return useGetContentPage('header.linkedItems[0].elements', null)
})
const isHomepage = computed(() => {
  return pageContext.urlPathname === '/'
})
const globalFooter = computed(() => {
  return useGetContentFooter('elements', null)
})
const pageFooter = computed(() => {
  return useGetContentPage('footer.linkedItems[0].elements', null)
})
const noFollow = computed(() => {
  return useGetContentPage('enable_nofollow.value[0].codename', null)
})
const contentHeaderId = computed(() => {
  return pageHeader.value ? useGetContentPage('header.system.id', '') : useGetContentHeader('system.id', '')
})
const contentFooterId = computed(() => {
  return pageFooter.value ? useGetContentPage('footer.system.id', '') : useGetContentFooter('system.id', '')
})
const cookies = computed(() => {
  return useGetContentOptions('cookies.linkedItems[0]', null)
})

const vwoKeys = computed(() => {
  return vwoStore.vwoActivatedCampaigns.map((campaign) => {
    if (campaign.assignedVariantName.includes('Variation')) {
      return [campaign.campaign_key.value, campaign.assignedVariantName]
    }
  })
})

const setPageButtonColour = () => {
  useSetBGColourPageOption(pageButtonColourVar, 'button_colour.value[0].name')
}

onBeforeMount(() => {
  setPageButtonColour()
})

onMounted(() => {
  afterMount.value = true
})

onUpdated(() => {
  setPageButtonColour()
})
</script>

<style lang="scss">
.landing-page-template.page-btn-colour {
  a.cta-btn.m-primary,
  button.cta-btn.m-primary {
    background-color: v-bind(pageButtonColourVar);
    transition: all 0.3s ease;

    &:active,
    &:focus {
      outline: 4px solid color-mix(in srgb, v-bind(pageButtonColourVar), #000 15%);
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);
    }

    &:hover {
      background-color: color-mix(in srgb, v-bind(pageButtonColourVar), #000 15%);
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;
      background: $gs-200;
      color: $gs-500;
    }
  }

  .sticky-nav-container {
    background: v-bind(pageButtonColourVar);

    @include media-query('desktop') {
      background: $headers;
    }
  }
}

.loader {
  min-height: 1000px;
}
</style>
