import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'
import { getDataLayer, checkOneTrustLoadState, mapDataLayerObj } from '#root/utils/helpers/helperTracking.js'
import { useSetter } from '#root/utils/helpers/helperRef.js'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { usePagesStore } from '#root/stores/storePages.js'
import { e164NumCodes } from '#root/utils/types/tracking.js'

export const useTrackingStore = defineStore('tracking', () => {
  const gtm = useGtm()
  const globalStore = useGlobalStore()
  const [lastActiveField, setLastActiveField] = useSetter(null)
  const [interactionIntentTracked, setInteractionIntentTracked] = useSetter(false)
  const [abandonTracked, setAbandonTracked] = useSetter(false)
  const [ongoingLeadType, setOngoingLeadType] = useSetter(null)
  const [webRefCode, setWebRefCode] = useSetter(null)
  const [clickRef, setClickRef] = useSetter(null)
  const [gtmActive, setGtmActive] = useSetter(false)
  const [onetrustAccepted, setOnetrustAccepted] = useSetter(false)
  const [inContentCtaCountersElms, setInContentCtaCountersElms] = useSetter({
    quote: [],
    learnMore: []
  })

  // store actions
  const clearOngoingLeadType = () => {
    ongoingLeadType.value = null
  }

  const mapDataLayerOneTrustLoadOrder = (pageContext) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }
    getDataLayer().unshift(mapDataLayerObj(pageContext))
    checkOneTrustLoadState(pageContext)
  }

  const trackFormFieldInteraction = (event) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    const field = event.currentTarget
    // commit('setLastActiveField', field)

    const wasValid = field.value !== ''
    if (wasValid === true) {
      gtm.trackEvent({
        interactionCategory: 'FormTracking',
        interactionAction: 'CompleteField',
        interactionLabel: field.getAttribute('name'),
        event: 'nfs.formTracking'
      })

      if (interactionIntentTracked.value === false) {
        gtm.trackEvent({
          interactionCategory: 'FormTracking',
          interactionAction: 'InteractionIntent',
          interactionLabel: field.getAttribute('name'),
          event: 'nfs.formTracking'
        })
        // commit('setInteractionIntentTracked', true)
        setInteractionIntentTracked(true)
      }
    } else {
      gtm.trackEvent({
        interactionCategory: 'FormTracking',
        interactionAction: 'SkipField',
        interactionLabel: field.getAttribute('name'),
        event: 'nfs.formTracking'
      })
    }
  }

  const trackRouteChange = (pageContext) => {
    if (!globalStore.trackingEnabled) return
    const pageStore = usePagesStore()
    gtm.trackEvent({
      event: 'content-view',
      'content-name': pageContext.urlPathname,
      'content-view-name': pageStore.template
    })
  }
  const trackQuoteAndBuyInteraction = ({ actionType }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    // actionType is types-tracking.js->qbAction
    gtm.trackEvent({
      interactionCategory: 'QuoteAndBuy',
      interactionAction: actionType,
      interactionLabel: 'QuoteAndBuyV4Action',
      event: 'nfs.siteAnaltyicsEvent'
    })
  }
  const trackQuoteAndBuyInit = ({ productCode, productName }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [
            {
              name: productName,
              id: productCode,
              quantity: 1
            }
          ]
        }
      }
    })
  }
  const trackStepOnThirdParties = ({ actionType }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    // actionType is types-tracking.js->qbActionForExternal
    const fullPathUrl = window.location.pathname.replace(/\/$/, '') + '/' + actionType

    // HotJar only defined on live
    if (typeof hj !== 'undefined') {
      const hjUrlToTrack = fullPathUrl
      hj('vpv', hjUrlToTrack)
      hj('stateChange', hjUrlToTrack)
    }
    gtm.trackEvent({
      virtualPageUrl: fullPathUrl,
      event: 'virtualPageView'
    })
  }
  const trackHjRecordFb = ({ step }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    const fullPathUrl = window.location.pathname.replace(/\/$/, '') + '/' + step

    // HotJar only defined on live
    if (typeof hj !== 'undefined') {
      const hjUrlToTrack = fullPathUrl
      hj('trigger', 'recordFb')
      hj('stateChange', hjUrlToTrack)
    }
  }
  const trackStepLoaded = ({ step }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step
          }
        }
      }
    })
  }
  const trackPurchaseComplete = ({ rootState }, { policyId, yearlyPremium, productName, productCode }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    const leadId = rootState.global.transactionId
    const cjEvent = clickRef.value || ''
    const ppclid = rootState.global.queryParams.ppclid || ''
    const ppcrid = rootState.global.queryParams.ppcrid || ''
    const ppsubid = rootState.global.queryParams.ppsubid || ''

    gtm.trackEvent({
      // Missing event name
      ecommerce: {
        purchase: {
          actionField: {
            id: policyId,
            revenue: yearlyPremium
          },
          products: [
            {
              name: productName,
              id: productCode,
              quantity: 1
            }
          ]
        }
      }
    })

    gtm.trackEvent({
      leadType: 'Sale',
      leadId,
      quoteId: policyId,
      yearlyPremium,
      currentProductCode: productCode,
      productName,
      cjEvent,
      ppclid,
      ppcrid,
      ppsubid,
      event: 'nfs.saleMade'
    })
  }

  const trackLead = ({ leadType, gtmCompanyName, formType }) => {
    const { queryParams, transactionId } = useGlobalStore()

    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    const ppclid = queryParams?.ppclid || ''
    const ppcrid = queryParams?.ppcrid || ''
    const ppsubid = queryParams?.ppsubid || ''
    const leadId = transactionId
    const pushData = {
      ppclid,
      ppcrid,
      ppsubid,
      leadType,
      leadId,
      ...(formType ? { formType: formType } : { formType: leadType }),
      ...(gtmCompanyName ? { 'thank-you-page': gtmCompanyName } : ''),
      event: 'nfs.leadCreated'
    }

    gtm.trackEvent(pushData)
    // commit('clearOngoingLeadType')
    clearOngoingLeadType()
  }
  const trackMultiStepProgress = ({ step, index, province = false }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      interactionCategory: 'form progression',
      interactionAction: `step ${index}`,
      interactionLabel: step,
      'interaction-type': false,
      event: 'nfs.formProgression',
      ...(step.includes('location') && province ? { province: province } : {})
    })
  }
  const trackABTesting = ({ testName, testVariation }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      abTestName: testName,
      abTestVariation: testVariation,
      event: 'nfs.abTestingUpdate'
    })
  }
  const trackUserInfo = ({ firstName, lastName, phoneH, phoneM, dob, email }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    const phoneToSend = phoneH || phoneM
    const phoneToUse = phoneToSend
      ? `${e164NumCodes[globalStore.locale]}${phoneToSend.charAt(0) === '0' ? phoneToSend.substring(1) : phoneToSend}`
      : ''
    const emailToSend = email ? email.toLowerCase() : ''
    if (firstName && lastName) {
      const d = dob ? new Date(dob) : ''
      const userInfo = {
        send: true,
        fn: firstName.toLowerCase(),
        ln: lastName.toLowerCase(),
        ph: phoneToUse,
        dob: d ? ''.concat(d.getFullYear(), d.getMonth() + 1, d.getUTCDate()) : '',
        em: emailToSend,
        createdBy: 1
      }

      gtm.trackEvent({
        // Missing event property, using 'nfs.userInfo' temporary
        userInfo,
        event: 'nfs.userInfo'
      })
    }
  }
  const trackCallback = ({ label }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      interactionCategory: 'AppointmentTracking',
      interactionAction: 'Clicked',
      interactionLabel: label,
      event: 'nfs.appointmentTracking'
    })
  }

  const trackFuneralTier = ({ funeralTier }) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      funProdVar: funeralTier,
      event: 'nfs.funeralProd'
    })
  }

  const trackFormError = (formFieldName, errorLog, quoteFormType) => {
    if (globalStore.isBot || !globalStore.trackingEnabled) {
      return
    }

    gtm.trackEvent({
      formFieldName,
      errorLog,
      quoteFormType,
      event: 'nfs.formErrorLog'
    })
  }

  return {
    lastActiveField,
    interactionIntentTracked,
    abandonTracked,
    ongoingLeadType,
    webRefCode,
    clickRef,
    gtmActive,
    setLastActiveField,
    setInteractionIntentTracked,
    setAbandonTracked,
    setOngoingLeadType,
    clearOngoingLeadType,
    setWebRefCode,
    setClickRef,
    mapDataLayerOneTrustLoadOrder,
    setGtmActive,
    trackFormFieldInteraction,
    trackQuoteAndBuyInteraction,
    trackQuoteAndBuyInit,
    trackStepOnThirdParties,
    trackHjRecordFb,
    trackStepLoaded,
    trackPurchaseComplete,
    trackLead,
    trackABTesting,
    trackUserInfo,
    trackCallback,
    trackMultiStepProgress,
    trackFuneralTier,
    trackFormError,
    trackRouteChange,
    onetrustAccepted,
    setOnetrustAccepted,
    inContentCtaCountersElms,
    setInContentCtaCountersElms
  }
})
